import { thecarmenFont, thejamsilFont } from 'src/styles/font';
import { rem } from 'src/styles/function';
import { theme } from 'src/styles/theme';
import { ICON_ROOT_BOTTLE } from 'src/utils/constants';
import styled, { css, keyframes } from 'styled-components';

/**
 * @name 밑으로 위로 올라오는 팝업 키프레임
 */
export const KeyframeSlideUpPopup = keyframes`
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(calc(-100% + 1px));
  }
`;
export const KeyframeSlideDownPopup = keyframes`
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
`;

/**
 * @name 밑에서 위로 올라오는 팝업 스타일 컴포넌트
 */
export const StyledSlideUpPopupWrapper = styled.div<{ zIndex?: number }>`
  position: fixed;
  overflow: hidden;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 201)};
  top: 100%;
  left: 0;
  max-height: 95vh;
  width: 100%;
  background-color: white;
  border-radius: ${rem(24)} ${rem(24)} 0 0;
  &.open {
    animation: ${KeyframeSlideUpPopup} 0.5s 0s ease 1 forwards;
  }
  &.close {
    animation: ${KeyframeSlideDownPopup} 0.5s 0s ease 1 backwards;
  }
`;

/**
 * @name 밑으로 위로 올라오는 팝업 키프레임
 */
export const KeyframeSlideUpFullScreenPopup = keyframes`
  0%{
    transform: translateY(100%);
  }
  100%{
    transform: translateY(0%);
  }
`;
export const KeyframeSlideDownFullScreenPopup = keyframes`
  0%{
    transform: translateY(0%);
  }
  100%{
    transform: translateY(100%);
  }
`;

/**
 * @name 밑에서 위로 올라오는 풀스크린 팝업 스타일 컴포넌트
 */
export const StyledSlideUpFullScreenPopupWrapper = styled.div<{
  zIndex?: number;
  ratioFromTop?: number; //상단과의 간격(비율)
}>`
  position: fixed;
  height: 100%;
  z-index: ${props => (props.zIndex ? props.zIndex : 201)};
  top: ${props => (props.ratioFromTop ? `${props.ratioFromTop}%` : `${0}%`)};
  width: 100%;
  background-color: white;
  min-height: 100vh;
  &.open {
    animation: ${KeyframeSlideUpFullScreenPopup} 0.5s 0s ease 1 forwards;
  }
  &.close {
    animation: ${KeyframeSlideDownFullScreenPopup} 0.5s 0s ease 1 backwards;
  }
`;

/**
 * @name 밑에서 위로 올라오는 팝업 하단 버튼 스타일 컴포넌트
 */

export const StyledSlideUpPopupBottomButton = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  z-index: 100;

  button.reservation {
    width: 100%;
    height: ${rem(48)};
    background-color: #00484d;
    ${thecarmenFont('regular')}
    font-size: ${rem(16)};
    color: #fff;
  }
`;

/**
 * @name 중앙 팝업 스타일 컴포넌트
 */
export const StyledCenteredPopupWrapper = styled.div<{
  padding?: string;
  zIndex?: number;
  isBanner?: boolean;
}>`
  z-index: ${props => (props.zIndex ? props.zIndex : 301)};
  box-sizing: border-box;
  display: block;
  position: fixed;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  width: 90%;
  background: white;
  border-radius: 0.25rem;
  padding: 1rem;
`;

/**
 * @name 확인/취소 팝업
 */
export const StyledConfirmPopupWrapper = styled.div<{
  isBanner?: boolean;
  padding?: string;
}>`
  background-color: white;
  border-radius: ${rem(20)};
  ${props =>
    props.isBanner &&
    css`
      margin-top: ${rem(20)};
      box-sizing: border-box;
      padding: ${props.padding
        ? props.padding
        : `${rem(50.5)} ${rem(20)} ${rem(20)}`};
    `}
`;

/**
 * @name 팝업 헤더 스타일 컴포넌트
 */
export const StyledPopupHeader = styled.div`
  border-radius: 1.5rem 1.5rem 0 0;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  h3 {
    text-align: center;
    margin: ${rem(22)} ${rem(30)} ${rem(25.5)} ${rem(20)};
    ${thejamsilFont('regular')};
    font-size: ${rem(17)};
  }
  .ico_close_wrapper {
    width: ${rem(15)};
    height: ${rem(15)};
    position: absolute;
    top: 0.9rem;
    right: ${rem(16)};
  }
`;

/**
 * @name 팝업 메시지 공통 스타일 컴포넌트
 */
export const StyledPopupMessage = styled.div`
  font-size: ${rem(15)};
  line-height: ${rem(22)};
  ${thecarmenFont('regular')};
  color: ${({ theme }) => theme.colors.black};

  p {
    font-size: ${rem(16)};
    line-height: 1.5;
    ${thecarmenFont('regular')};
    color: ${({ theme }) => theme.colors.black};
  }

  .msg {
    &.title {
      ${thecarmenFont('bold')};
      font-size: ${rem(20)};
    }

    &.bold {
      font-size: ${rem(20)};
      ${thecarmenFont('bold')}
    }

    &.semibold {
      font-size: ${rem(15)};
      ${thecarmenFont('semibold')}
    }

    &.medium {
      font-size: ${rem(16)};
      ${thecarmenFont('medium')}
    }

    &.regular {
      font-size: ${rem(13)};
      ${thecarmenFont('regular')}
    }

    &.color-gray {
      color: #727171;
    }
  }

  .center {
    text-align: center;
  }

  .left {
    text-align: left;
  }
`;

/**
 * @name 확인/취소 팝업 배너 스타일 컴포넌트
 */
export const StyledConfirmPopupBanner = styled.div`
  z-index: 302;
  width: 100%;
  img {
    width: 100%;
  }
`;

/**
 * @name 확인/취소 팝업 스타일 컴포넌트
 */
export const StyledConfirmPopup = styled.div`
  margin: ${rem(16)} 0 ${rem(30)} 0;
  text-align: center;
  p {
    ${thecarmenFont('regular')};
    font-size: ${rem(15)};
    color: #363434;
    line-height: ${rem(22)};
  }
`;

/**
 * @name 확인 팝업 내용 스타일 컴포넌트
 */
export const StyledAlertPopupContent = styled.div`
  text-align: center;
  padding: ${rem(16)} 0 ${rem(32)} 0;
`;

/**
 * @name 메인팝업 스타일 컴포넌트
 */
export const StyledMainPopupContent = styled.div`
  .contents {
    width: 100%;
    .swiper {
      border-radius: ${rem(20)} ${rem(20)} 0 0;
      .swiper-slide {
      }
    }
    .swiper-pagination {
      position: absolute;
      top: ${rem(16)};
      right: ${rem(16)};
      left: auto;
      bottom: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${rem(32)};
      padding: ${rem(6)};
      background: #cdcdcd;
      border-radius: ${rem(2)};
      ${thecarmenFont('light')};
      color: #fff;
      font-size: ${rem(12)};
    }
  }
  .btns {
    height: ${rem(48)};
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    button {
      ${thecarmenFont('regular')};
      color: #363434;
    }
  }
`;

/**
 * @name 로딩팝업 스타일 컴포넌트
 */
// const logo_moving2 = keyframes`
//   0% {
//     background-position-x: 0px;
//   }
//   10% {
//     background-position-x: -100px;
//   }
//   20% {
//     background-position-x: -200px;
//   }
//   30% {
//     background-position-x: -300px;
//   }
//   40% {
//     background-position-x: -400px;
//   }
//   50% {
//     background-position-x: -500px;
//   }
//   60% {
//     background-position-x: -600px;
//   }
//   70% {
//     background-position-x: -700px;
//   }
//   80% {
//     background-position-x: -800px;
//   }
//   90% {
//     background-position-x: -900px;
//   }
//   100% {
//     background-position-x: -1000px;
//   }
// `;

export const StyledLodingPopup = styled.div`
  .loading {
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 50%;
    background-color: #fff;
    border-radius: ${rem(80)};
    transform: translateX(-50%) translateY(-50%);

    > img {
      width: ${rem(80)};
      height: ${rem(80)};
      padding: ${rem(16)};
    }
  }
`;

/**
 * @name 인트로 팝업 스타일 컴포넌트
 */
export const StyledIntroPopup = styled.div`
  position: absolute;
  overflow: scroll;
  z-index: 710;
  top: 0;
  width: 100%;
  height: 100vh;

  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);

  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  .swiper-pagination {
    position: absolute;
    height: ${rem(11)};
    top: ${rem(33.5)};
    left: ${rem(35)};
    text-align: left;
  }

  .swiper-pagination-bullet {
    background-color: #9fa0a0;
    width: ${rem(8)};
    height: ${rem(8)};
    opacity: 100%;
  }

  .swiper-pagination-bullet-active {
    background-color: #d74728;
  }

  img {
    height: 100%;
  }

  .signup {
    position: absolute;
    height: ${rem(11)};
    top: ${rem(16)};
    right: ${rem(35)};
    text-align: left;
    z-index: 711;
  }

  .btns {
    position: fixed;
    bottom: ${rem(0)};
    width: 100%;
    z-index: 300;
  }
`;

/**
 * @name 주이용점포 설정 팝업 스타일 컴포넌트
 */
export const StyledMartSettingPopup = styled.div`
  .con {
    padding: ${rem(50)} 0 ${rem(22)} 0;

    .logo {
      margin: 0 ${rem(108)} 0 ${rem(108)};
      > img {
        width: 100%;
      }
    }

    > h5 {
      margin: ${rem(19.5)} 0 0 0;
      ${thecarmenFont('bold')}
      color: #000;
      font-size: ${rem(20)};
      text-align: center;
    }

    > p {
      margin: ${rem(16)} 0 0 0;
      ${thecarmenFont('regular')}
      color: #727171;
      font-size: ${rem(15)};
      text-align: center;
    }

    > ul.list {
      margin: ${rem(16)} 0 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      > li {
        width: 30%;
        flex-basis: 30%;
        border: 1px solid #dcdddd;
        padding: ${rem(17.5)} ${rem(16)} ${rem(17.5)} ${rem(16)};
        text-align: center;

        > span {
          ${thecarmenFont('semibold')}
          color: #000;
          font-size: ${rem(14)};
        }

        &:nth-child(odd) {
          margin: ${rem(8)} ${rem(4)} 0 0;
        }
        &:nth-child(even) {
          margin: ${rem(8)} 0 0 ${rem(4)};
        }

        &.active {
          background: #d74728;
          border: #d74728;
          > span {
            color: #fff;
          }
        }
      }
    }
  }
  .btn_area {
    margin: ${rem(10)} ${rem(5)};
  }

  p.notice {
    ${thecarmenFont('regular')}
    color: #727171;
    font-size: ${rem(14)};
    line-height: 1.2;
    > strong {
      color: ${theme.colors.red};
    }
  }
`;

export const StyledInspection = styled.div`
  padding: ${rem(60)} ${rem(40)};
  position: fixed;
  overflow: scroll;
  z-index: 920;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  .title {
    .grid {
      display: grid;
      grid-template-columns: auto ${rem(100)};
      grid-template-rows: auto;
      .grid_text {
        ${thejamsilFont('medium')}
        font-size: ${rem(24)};
        top: 1rem;
        position: relative;
        h1 {
          color: ${({ theme }) => theme.colors.red};
          margin-top: ${rem(12)};
        }
      }

      img {
        width: ${rem(100)};
        height: ${rem(100)};
      }
    }
    .grid_desc {
      ${thejamsilFont('light')}
      font-size: ${rem(20)};
      line-height: ${rem(24)};
      letter-spacing: -0.05rem;
      text-align: center;
      margin: 2rem 0;
      h3 {
        font-size: ${rem(18)};
        ${thejamsilFont('regular')}
        margin-bottom : 1rem;
      }
      p {
        font-size: ${rem(14)};
        line-height: 1.5;
        color: #666;
      }
    }
  }

  .contents {
    ${thejamsilFont('light')}
    font-size: ${rem(14)};
    height: auto;
    background-color: #fff;
    box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    border-radius: 0.5rem;
    ul {
      li {
        padding: 1rem;
        p {
          text-align: center;
          span {
            ${thejamsilFont('regular')}
          }
          font-size: ${rem(14)};
          line-height: 1.5;
        }
        .barcode {
          width: 100%;
          height: ${rem(100)};
          margin: auto;
          svg {
            width: 100%;
          }
        }
      }
      li:not(:last-child) {
        border-bottom: solid 1px #e6e6e6;
      }
    }
  }
  button {
    ${thejamsilFont('regular')}
    font-size: ${rem(16)};
    margin-top: 2rem;
  }
  .logo {
    width: ${rem(100)};
    margin: 0 auto;
    padding-top: 9rem;
    img {
      width: 100%;
    }
  }
`;

/**
 * @name 바코드팝업
 */
export const StyledBarcodePopup = styled.div`
  .header {
    padding: 1rem 0 0 0.5rem;
    > .tit {
      ${thecarmenFont('bold')}
      color: ${({ theme }) => theme.colors.black};
      font-size: 1.4rem;
    }
  }
  .txt {
    padding: 1.5rem 0 1rem 0.5rem;
    font-size: 1rem;
    line-height: 1.5;
    ${thecarmenFont('semibold')}
    color: ${({ theme }) => theme.colors.bottle_green};
  }
  .barcode {
    text-align: center;
  }
`;
/**
 * @name 팝업 광고성 정보 수신동의
 */
export const StyledPopupMessageAgreeAdvertisingPush = styled.div`
  .title {
    position: relative;
    // margin: 0 0 2rem;
    ${thecarmenFont('bold')}
    color: #000;
    font-size: 1.4rem;
    // line-height: 2.4rem;
  }
  .desc {
    ${thecarmenFont('regular')}
    color:#727171;
    margin: 0.5rem 0 0;
    letter-spacing: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    word-break: break-all;

    &.normal {
      border-top: 1px solid #80b9af;
      margin: 1.5rem 0 0;
    }

    tbody th {
      text-align: left;
      padding: 0.8rem 0;
      font-size: 0.9rem;
      // line-height: 1.8rem;
      ${thecarmenFont('regular')}
      color:#00484d;
      // @include CarmenFont(Regular, #00484d);
      border-bottom: 1px solid #80b9af;
      border-left: 1px solid #80b9af;
      &:first-child {
        border-left: none;
      }

      &.second {
        padding: 0.8rem 1.2rem;
      }
    }
  }

  .notice {
    margin: 1.5rem 0 0 0;
    padding: 1rem;
    background: #f4f4f4;

    > strong {
      position: relative;
      display: block;
      padding: 0 0 0 1.2rem;
      margin: 0 0 1rem;
      font-size: 0.9rem;
      ${thecarmenFont('regular')}
      color: #000;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 0.9rem;
        height: 0.9rem;
        background-image: url('${ICON_ROOT_BOTTLE}/icon_mark.png');
        background-size: 0.9rem auto;
      }
    }

    > ul {
      margin: 1rem 0 0;
      > li {
        margin: 0.5rem 0 0 0;
        list-style: none;

        &:first-child {
          margin: 0;
        }

        > p {
          position: relative;
          padding: 0 0 0 0.8rem;
          line-height: 1.2;
          font-size: 0.9rem;
          letter-spacing: 0;
          ${thecarmenFont('regular')}
          color: #727171;
          &:before {
            content: '';
            position: absolute;
            top: 0.7rem;
            left: 0;
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 0.3rem;
            background: #727171;
          }
        }
      }
    }
  }
`;

/**
 * @name 매장정보팝업 스타일 컴포넌트
 */
export const StyledStoreInfoPopup = styled.div`
  min-height: ${rem(320)};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      ${thecarmenFont('bold')}
      color:${({ theme }) => theme.colors.black};
      font-size: ${rem(24)};
    }
  }

  .filter {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;

    > select {
      font-size: ${rem(14)};
      ${thecarmenFont('semibold')}
      color: #000;
      padding: ${rem(8)} ${rem(4)};
    }
    .ico {
      display: block;
      width: ${rem(16)};
      height: ${rem(16)};
    }
  }

  .con {
    margin: 0 0 ${rem(24)} 0;

    .subtitle {
      margin: ${rem(16)} 0 ${rem(8)};
      font-size: ${rem(16)};
      ${thecarmenFont('semibold')}
      color: #3f3a39;
      line-height: 1.3;
    }

    p.txt {
      margin: ${rem(8)} 0 0;
      letter-spacing: 0;
      ${thecarmenFont('regular')}
      font-size: ${rem(14)};
      color: ${({ theme }) => theme.colors.gray_txt};
      line-height: 1.3;
      display: flex;
      align-items: center;
    }

    button.call {
      margin-left: ${rem(4)};
      > i {
        display: block;
        width: ${rem(24)};
        height: ${rem(24)};
      }
    }

    button.btn {
      text-decoration: underline;
      margin: ${rem(8)} 0 0;
      letter-spacing: 0;
      ${thecarmenFont('regular')}
      font-size: ${rem(14)};
      color: ${({ theme }) => theme.colors.red};
      line-height: 1.3;

      &.icon {
        position: relative;
        padding: 0 0 0 ${rem(20)};
        > i {
          position: absolute;
          top: 0.1rem;
          left: 0.1rem;
          width: ${rem(12)};
          height: ${rem(16)};
        }
      }
    }
  }
`;
