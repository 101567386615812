import axios from 'axios';
import { getMartgoTkn } from './sso';
import { apiUserSsoTokenRefresh } from 'src/api/apiUserSso';
import { bridgeGetToken } from './bridge';
import { isAndWebView, isIOSWebView } from './utility';

/**
 * @name 공통API 호출 유틸
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
});

/**
 * @name 토큰조회체크 앱에서 조회한 토큰이 storage에 저장되었는지 체크
 */
const checkedToken = () => {
  return new Promise(resolve => {
    const timerId = setInterval(() => {
      const martgoTkn = getMartgoTkn();
      if (martgoTkn && martgoTkn.access_token) {
        clearInterval(timerId);
        resolve(martgoTkn.access_token);
      }
    }, 500);
  });
};

// 요청 인터셉터
api.interceptors.request.use(
  async config => {
    const objMartgoTkn = getMartgoTkn();
    let access_token = objMartgoTkn && objMartgoTkn.access_token;
    const refresh_token = objMartgoTkn && objMartgoTkn.refresh_token;
    /**
     * @name 공통API headers 세팅
     * @param x-ssp-token 로그인 시 저장한 접근토큰
     * @param x-ssp-refresh-token 로그인 시 저장한 갱신토큰
     * @param x-ssp-channel 접근 채널 (롯데마트GO:1 / 롯데마트BO:2 / 보틀벙커:3 / 보틀벙커BO:4 / POS:5)
     * @param x-ssp-gateway-service-id 접근 서비스 (USER/COUPON/EVENT/CLUB/PUSH/SCANGO/PRODUCT/BOTTLE)
     * @description x-ssp-gateway-service-id는 호출 시 헤더에 별도 세팅 필요. E_API_SERVICEID 사용
     */
    //토큰없이 조회하는 화면 or api의 경우 토큰 조회 제외
    const isNotTokenPage =
      location.pathname.indexOf('/login') > -1 ||
      location.pathname.indexOf('/signup') > -1 ||
      location.pathname.indexOf('/system') > -1 ||
      SKIP_ALL_API.indexOf(config.url || '') > -1 ||
      //AS-IS 업데이트 후 api 호출 시 마트GO 토큰 없음 - 예외처리
      localStorage.getItem('AUTO_LOGIN_STATE') === 'true'
        ? true
        : false;

    if (!isNotTokenPage) {
      console.log('토큰이 필요한 API입니다. api.ts 모듈을 확인해주세요');
    }

    if (!access_token && !isNotTokenPage && (isAndWebView || isIOSWebView)) {
      //스토리지에 토큰 없는 경우 앱에서 토큰 조회
      bridgeGetToken();
      const newToken = await checkedToken(); //앱에서 새로운 토큰 발급시까지 대기
      access_token = newToken;
    }

    config.headers['x-ssp-token'] = access_token || '';
    config.headers['x-ssp-refresh-token'] = refresh_token || '';
    config.headers['x-ssp-channel'] = '3';
    config.headers['Content-Type'] = 'application/json; charset=utf-8';

    return config;
  },
  async error => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터
api.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    const objMartgoTkn = getMartgoTkn();
    const access_token = objMartgoTkn && objMartgoTkn.access_token;
    const status = error.response && error.response.status;
    const config = error.config;

    if (access_token) {
      //토큰 만료 시 갱신

      if (
        status === 401 &&
        config.url !== '/tokens/token-refresh' &&
        config.baseURL === process.env.REACT_APP_BASE_API
      ) {
        //return;
        const newTokenData = await apiUserSsoTokenRefresh();

        if (newTokenData) {
          config.headers['x-ssp-token'] = newTokenData.token;
          config.headers['x-ssp-refresh-token'] = newTokenData.refreshToken;

          // return api.request(config);
          return axios(config).then(response => response.data);
        } else {
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export default api;

/**
 * @name 토큰없이 조회하는 API 목록
 */
const SKIP_ALL_API = [
  //'/fo/user/ssobottle/login/auto-sso',
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/login/auto-sso`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/login/auto-bt`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/login/lpoint`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/sso/signup/sso-agmt`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/sso/signup`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/sso/signup/agmt/list`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/agmt/list`,
  `${process.env.REACT_APP_BASE_API_MARTGO}/fo/user/ssobottle/signup`,

  '/app/store/list',
  '/app/store/info',
  '/v2/hybridapp/common/crnttime',

  '/app/main/banner/area/info',
  '/app/main/monthlyitem/list',
  '/app/main/theme/list',
  '/app/main/weeklyWine/cdlist',
  '/app/main/weeklyWine/list',
  '/app/main/bestseller/liquor/list',
  '/app/main/recommItem/list',
  '/app/main/popup/list',
  '/app/main/splash/list',

  '/v2/hybridapp/mypage/faq/list',

  '/v2/hybridapp/mypage/notice/list',
  '/v2/hybridapp/mypage/notice/info',

  '/app/foodFairing/foodcate/list',
  '/app/foodFairing/food/list',
  '/app/foodFairing/info',

  '/app/winepedia/list',
  '/v2/hybridapp/winepedia/detail',
  '/v2/hybridapp/common/view/count/update',

  '/app/search/type/list',
  '/app/search/topwrd/list',
  '/app/search/recomwrd/list',
  '/app/search/autocomplete',
  '/app/search/insertsuccwd',
  '/app/search/wine/conditional',
  '/app/search/liquor/conditional',
  '/app/search/wine/result/list',
  '/app/search/liquor/result/list',
  '/app/product/info',

  '/app/tasting/thema/list',
  '/app/tasting/thema/info',

  '/app/class/list',

  '/v2/hybridapp/main/theme/list',
  '/app/main/theme/info',
  '/app/main/theme/item/list',

  '/app/product/wineToMatch',

  '/v2/hybridapp/event/list',
  '/v2/hybridapp/event/win/show',

  '/app/exhibition/list',
  '/app/exhibition/show',

  '/app/product/init',
  '/app/product/rsrvbtn/info',
  '/app/product/wine/styleTab/info',
  '/app/product/wine/infoTab/info',
  '/app/product/wine/wineryTab/info',
  '/app/product/liquor/infoTab/info',
  '/app/product/liquor/styleTab/info',
  '/app/product/liquor/distilleryTab/info',
  '/app/product/item/image',

  '/app/main/monthlyitem/show',
];
