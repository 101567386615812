import { action } from 'typesafe-actions';
import ActionTypes from '../constants/appConstants';
import {
  TAlertPopupProps,
  TConfirmPopupProps,
  TDeviceInfo,
  TMartSettingPopupProps,
  TTermsPopupProps,
} from '../types/appTypes';

export type AppAction =
  | {
      type: typeof ActionTypes.OPEN_CONFIRM_POPUP;
      payload: {
        confirmPopupState: TConfirmPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_CONFIRM_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_ALERT_POPUP;
      payload: {
        alertPopupState: TAlertPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_ALERT_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_TERMS_POPUP;
      payload: {
        termsPopupState: TTermsPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_TERMS_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_LOADING_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_LOADING_POPUP;
    }
  | {
      type: typeof ActionTypes.APP_DEVICE_INFO;
      payload: {
        deviceInfo: TDeviceInfo;
      };
    }
  | {
      type: typeof ActionTypes.OPEN_MART_SETTING_POPUP;
      payload: {
        martSettingPopupState: TMartSettingPopupProps;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_MART_SETTING_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_INSPECTION_APPCARD_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_INSPECTION_APPCARD_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_BARCODE_POPUP;
    }
  | {
      type: typeof ActionTypes.CLOSE_BARCODE_POPUP;
    }
  | {
      type: typeof ActionTypes.OPEN_STOREINFO_POPUP;
      payload: {
        strCd?: string;
      };
    }
  | {
      type: typeof ActionTypes.CLOSE_STOREINFO_POPUP;
    }
  | {
      type: typeof ActionTypes.APP_PREV_LOCATION;
      payload: {
        prevUrl: string;
      };
    };

/**
 * @name 확인/취소 팝업 열기
 */
export const actionOpenConfirmPopup = (
  confirmPopupState: TConfirmPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_CONFIRM_POPUP, { confirmPopupState });
};

/**
 * @name 확인/취소 팝업 닫기
 */
export const actionCloseConfirmPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_CONFIRM_POPUP);
};

/**
 * @name 알림 팝업 열기
 */
export const actionOpenAlertPopup = (
  alertPopupState: TAlertPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_ALERT_POPUP, { alertPopupState });
};

/**
 * @name 알림 팝업 닫기
 */
export const actionCloseAlertPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_ALERT_POPUP);
};

/**
 * @name 약관상세 팝업 열기
 */
export const actionOpenTermsPopup = (
  termsPopupState: TTermsPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_TERMS_POPUP, { termsPopupState });
};

/**
 * @name 약관상세 팝업 닫기
 */
export const actionCloseTermsPouup = (): AppAction => {
  return action(ActionTypes.CLOSE_TERMS_POPUP);
};

/**
 * @name 로딩팝업 열기
 */
export const actionOpenLoadingPopup = (): AppAction => {
  return action(ActionTypes.OPEN_LOADING_POPUP);
};

/**
 * @name 로딩팝업 닫기
 */
export const actionCloseLoadingPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_LOADING_POPUP);
};

/**
 * @name 앱 기기정보 저장
 */
export const actionDeviceInfoSave = (deviceInfo: TDeviceInfo): AppAction => {
  return action(ActionTypes.APP_DEVICE_INFO, { deviceInfo });
};

/**
 * @name 주이용점포 설정 팝업 열기
 */
export const actionOpenMartSettingPopup = (
  martSettingPopupState: TMartSettingPopupProps,
): AppAction => {
  return action(ActionTypes.OPEN_MART_SETTING_POPUP, { martSettingPopupState });
};

/**
 * @name 주이용점포 설정 팝업 닫기
 */
export const actionCloseMartSettingPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_MART_SETTING_POPUP);
};

/**
 * @name 점검안내(앱카드) 팝업 열기
 */
export const actionOpenInspectionAppcardPopup = (): AppAction => {
  return action(ActionTypes.OPEN_INSPECTION_APPCARD_POPUP);
};

/**
 * @name 점검안내(앱카드) 팝업 닫기
 */
export const actionCloseInspectionAppcardPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_INSPECTION_APPCARD_POPUP);
};
/**
 * @name 바코드 (앱카드) 팝업 열기
 */
export const actionOpenBarcodePopup = (): AppAction => {
  return action(ActionTypes.OPEN_BARCODE_POPUP);
};

/**
 * @name 바코드 (앱카드) 팝업 닫기
 */
export const actionCloseBarcodePopup = (): AppAction => {
  return action(ActionTypes.CLOSE_BARCODE_POPUP);
};

/**
 * @name 지점정보 팝업 열기
 */
export const actionOpenStoreInfoPopup = (strCd?: string): AppAction => {
  return action(ActionTypes.OPEN_STOREINFO_POPUP, { strCd });
};

/**
 * @name 지점정보 팝업 닫기
 */
export const actionCloseStoreInfoPopup = (): AppAction => {
  return action(ActionTypes.CLOSE_STOREINFO_POPUP);
};

/**
 * @name 이전 경로
 */
export const actionPrevUrlSave = (prevUrl: string): AppAction => {
  return action(ActionTypes.APP_PREV_LOCATION, { prevUrl });
};
