import React, { useEffect, useState, useCallback, lazy, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import { isAndWebView, isIOSWebView } from 'src/utils/utility';
import { storageMartgoTkn, storageMembersTkn } from 'src/utils/sso';
import {
  actionDeviceInfoSave,
  actionPrevUrlSave,
} from 'src/redux/actions/appActions';
import { TDeviceInfo } from 'src/redux/types/appTypes';
import {
  IS_BOTTLE_APP,
  IS_PINCH_ZOOM_LIST,
  LOCAL_DEVICE_INFO,
} from 'src/utils/constants';

import ProtalControl from './components/ProtalControl';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet-async';
import Portal from 'src/components/Portal';
import SystemInspection from '../SystemInspection';
import SystemUpdate from '../SystemUpdate';

const BasePageLazy = lazy(() => import('./components/BasePage'));

function App(): React.ReactElement {
  const [isSuccess, setIsSuccess] = useState(false);

  const location = useLocation();
  const prevLocation = useRef('');
  const dispatch = useDispatch();

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  /**
   * 로그인정보 조회
   */
  const getLoginInfo = () => {
    try {
      if (isIOSWebView) {
        webkit.messageHandlers.getLoginInfo.postMessage({
          callback: 'callbackGetLoginInfo',
        });
      } else if (isAndWebView) {
        window.binder?.getLoginInfo('callbackGetLoginInfo');
      }

      window['callbackGetLoginInfo'] = (
        access_token,
        refresh_token,
        acesTkn,
        rnwTkn,
        ssoTkn,
      ) => {
        const martGoTkn = {
          access_token: access_token,
          refresh_token: refresh_token,
        };

        const membersTkn = {
          acesTkn: acesTkn,
          rnwTkn: rnwTkn,
          ssoTkn: ssoTkn,
        };

        if (access_token) {
          storageMartgoTkn(martGoTkn);
        } //마트GO 토큰 저장

        if (acesTkn) {
          storageMembersTkn(membersTkn);
        } //멤버스 토큰 저장

        setIsSuccess(true);
      };
    } catch (e) {
      console.log('error bridge getLoginInfo');
      setIsSuccess(true);
    }
  };

  /**
   * APP : 앱정보 조회, 필수업데이트 체크
   * WEB : SKIP (setIsSuccess(true);)
   */
  const getAppInfo = useCallback(() => {
    try {
      //웹에서 진입시 디바이스 기본정보 셋팅
      if (!IS_BOTTLE_APP) {
        //로그인 화면에서 사용
        dispatch(actionDeviceInfoSave(LOCAL_DEVICE_INFO));
        setIsSuccess(true);
        return;
      }

      //앱에서 진입 시 디바이스 정보 조회
      if (isIOSWebView) {
        webkit.messageHandlers.getAppInfo.postMessage({
          callback: 'callbackGetAppInfo',
        });
      } else if (isAndWebView) {
        window.binder?.getAppInfo('callbackGetAppInfo');
      }

      window['callbackGetAppInfo'] = (
        auto,
        userId,
        os,
        osVer,
        modelNm,
        appVer,
        deviceId,
      ) => {
        localStorage.setItem('AUTO_LOGIN_STATE', auto);

        const device_os = os === 'I' ? 'IOS' : os === 'A' ? 'ANDROID' : '';
        const _deviceInfo: TDeviceInfo = {
          device_id: deviceId,
          device_os: device_os,
          model_nm: modelNm,
          app_ver: appVer,
          wdata: localStorage.getItem('wdata') || '',
          ukey: localStorage.getItem('ukey') || '',
        };
        dispatch(actionDeviceInfoSave(_deviceInfo));

        // 하드코딩으로 필수 업데이트 화면 이동 처리 로직
        // 버전정보 체크해서 이동 시킴
        const _updatePopupData = {
          ios_ver: '1.0.5', //업데이트할 버전정보 하드코딩
          and_ver: '1.0.8', //업데이트할 버전정보 하드코딩
        };

        if (process.env.REACT_APP_TYPE !== 'STAGE') {
          let _updateVer = '';

          if (isIOSWebView) {
            _updateVer = _updatePopupData.ios_ver;
          }

          if (isAndWebView) {
            _updateVer = _updatePopupData.and_ver;
          }

          if (_deviceInfo && _deviceInfo.app_ver < _updateVer) {
            setIsUpdate(true);
            return;
          } else {
            setIsUpdate(false);
          }
        }

        getLoginInfo();
      };
    } catch (e) {
      console.log('error bridge getAppInfo');
      setIsSuccess(true);
    }
  }, [dispatch]);

  /**
   * @name 앱 토큰 및 기기정보 조회
   * @description 로그인에 필요한 기기 정보 및 토큰 정보 조회
   */
  const [isInspection, setIsInspection] = useState<boolean>(false);

  useEffect(() => {
    // 이전 페이지 경로를 redux store에 저장하여 GA 태그정보 전송시 referrer로 사용
    // console.log('이전 페이지 : ', prevLocation.current);
    dispatch(actionPrevUrlSave(prevLocation.current));
    prevLocation.current = location.pathname;
  }, [dispatch, location]);

  useEffect(() => {
    // 점검안내(공사중) 노출 처리
    const _isInspection = false; // 하드코딩으로 true로 변경 시 적용)
    if (_isInspection && process.env.REACT_APP_TYPE !== 'STAGE') {
      setIsInspection(true);
      return;
    } else {
      setIsInspection(false);
    }

    getAppInfo();
  }, [getAppInfo]);

  /**
   * @name GA 설정
   */
  useEffect(() => {
    let gaTrackingId = '';

    // App/Mo 구분
    if (IS_BOTTLE_APP) {
      gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID_APP || '';
    } else {
      gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID_MO || '';
    }

    if (gaTrackingId) {
      ReactGA.initialize(gaTrackingId);
      // Family, Silver, Gold, Diamond
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content={`${
            IS_PINCH_ZOOM_LIST.includes(location.pathname)
              ? 'width=device-width, initial-scale=1.0, minimum-scale=0.5, maximum-scale=5.0, user-scalable=yes'
              : 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
          }`}
        />
      </Helmet>
      {isSuccess && !isInspection && !isUpdate && (
        <>
          <BasePageLazy />
          <ProtalControl />
        </>
      )}

      {isInspection && (
        <Portal id="inspectionPopup" isOpen={isInspection}>
          <SystemInspection />
        </Portal>
      )}

      {isUpdate && (
        <Portal id="updatePopup" isOpen={isUpdate}>
          <SystemUpdate />
        </Portal>
      )}
    </>
  );
}

export default App;
