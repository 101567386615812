import React from 'react';
import { IcoClose } from 'src/components/Icon';
import { StyledPopupHeader } from '../styles';

/**
 * @description 타이틀 + 닫기 아이콘
 */
export function PopupHeaderWithTitleAndClose(props: {
  onClose: () => void;
  title: React.ReactNode;
}): React.ReactElement {
  return (
    <StyledPopupHeader>
      <h3>{props.title}</h3>
      <button
        className={'ico_close_wrapper'}
        onClick={props.onClose}
        type="button"
        aria-label={`닫기`}
      >
        <i role="img" aria-label="닫기">
          <IcoClose />
        </i>
      </button>
    </StyledPopupHeader>
  );
}

/**
 * @description  닫기 아이콘
 */
export function PopupHeaderOnlyClose(props: {
  onClose: () => void;
}): React.ReactElement {
  return (
    <StyledPopupHeader>
      <button
        className={'ico_close_wrapper'}
        onClick={props.onClose}
        type="button"
        aria-label={`닫기`}
      >
        <i role="img" aria-label="닫기">
          <IcoClose />
        </i>
      </button>
    </StyledPopupHeader>
  );
}
