import { TLoginAppleSave } from 'src/types/loginTypes';
import api from 'src/utils/api';
import { E_API_SERVICEID } from 'src/utils/enum';

/**
 * @name 점검안내(앱카드) 팝업 Y/N 조회
 */
// export const apiGetInspectionAppcardYn = async params => {
//   const response = await api.post(
//     '/fo/event/eventhard/eventinfo',
//     {
//       ...params,
//     },
//     {
//       headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.EVENT },
//     },
//   );
//   const data = await response.data;

//   return data;
// };

/**
 * @name 사용자 정보 조회
 */
export const apiGetUserInfo = async () => {
  const response = await api.post(
    '/app/member/user/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 점포 목록 조회(보틀벙커)
 */
export const apiGetStoreList = async () => {
  const response = await api.post(
    '/app/store/list',
    {},
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 점포 상세 조회(보틀벙커)
 * @param str_cd 점포코드
 */
export const apiGetStoreInfo = async params => {
  const response = await api.post(
    '/app/store/info',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 주이용점포 변경
 * @param strCd 점포코드
 */
export const apiSetChangeMainStore = async params => {
  const response = await api.post(
    '/app/member/user/setDefaultStore',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE },
    },
  );

  const data = await response.data;
  return data;
};

/**
 * @name 스마트영수증 목록 조회
 */
export const apiGetReceiptList = async params => {
  const response = await api.post(
    '/v2/hybridapp/receipt/list',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 스마트영수증 매장 상세 조회
 * @param str_cd 점포코드
 * @param order_id 주문번호
 * @param order_date 주문일자
 * @param pos_no 포스번호
 * @param setl_id 결제번호
 */
export const apiGetOfflineReceiptDetail = async params => {
  const response = await api.post(
    '/v2/hybridapp/receipt/info',
    {
      ...params,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE },
    },
  );

  const data = await response.data;

  return data;
};

/**
 * @name 설정 푸시정보 정보 조회
 */
export const apiGetPushInfo = async () => {
  const response = await api.post(
    '/v2/hybridapp/mypage/setup/push/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 설정 정보 조회
 */
export const apiUpdatePushInfo = async params => {
  const response = await api.post(
    '/v2/hybridapp/mypage/setup/push/update',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;
  return data;
};

/**
 * @name 서버시간 조회
 */
export const apiGetCurrentTime = async () => {
  const response = await api.post(
    '/v2/hybridapp/common/crnttime',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 앱카드번호 조회
 */
export const apiGetAppCardInfo = async () => {
  const response = await api.post(
    '/v2/hybridapp/member/user/appcard/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 마이페이지 기본 정보 조회
 */
export const apiGetMyPageInitInfo = async () => {
  const response = await api.post(
    '/v2/hybridapp/mypage/init/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 유저 등급 정보 조회
 */
export const apiGetUserGradeInfo = async () => {
  const response = await api.post(
    '/v2/hybridapp/mypage/usergrade/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 등급 정보 조회
 */
export const apiGetGradeInfo = async () => {
  const response = await api.post(
    '/v2/hybridapp/mypage/grade/info',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 정보성 알림 목록
 */
export const apiGetNoticePushList = async () => {
  const response = await api.post(
    '/app/notice/push/list',
    {},
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 정보성 알림 삭제
 */
export const apiDeleteNoticePush = async params => {
  const response = await api.post(
    '/app/notice/push/delete',
    { ...params },
    {
      headers: {
        'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE,
      },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name PUSH Log 저장(읽음처리)
 * @param large_yn 대용량 PUSH 여부
 * @param seq_no PUSH 번호
 */
export const apiSetConnPushLog = async params => {
  const response = await api.post('/app/notice/push/read', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.BOTTLE },
  });

  const data = response.data;

  return data;
};

/**
 * @name 설정 멤버스 소셜로그인 연동조회
 */
export const apiUserMembsSocialInfo = async (acesTkn: string) => {
  const response = await api.post(
    '/fo/user/ssobottle/membs/social-info',
    {
      acesTkn: acesTkn,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 멤버스 소셜로그인 연동등록
 */
export const apiUserMembsSociaSave = async params => {
  const response = await api.post(
    '/fo/user/ssobottle/membs/social-save',
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 멤버스 소셜로그인 연동등록
 */
export const apiUserMembsSociaClear = async params => {
  const response = await api.post(
    '/fo/user/ssobottle/membs/social-clear',
    params,
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 애플로그인 연동해제
 */
export const apiUserAppleLoginClear = async (membs_on_cno: string) => {
  const response = await api.post(
    '/fo/user/ssobottle/apple-clear',
    {
      membs_on_cno: membs_on_cno,
    },
    {
      headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
    },
  );
  const data = await response.data;

  return data;
};

/**
 * @name 설정 애플로그인 연동등록
 */
export const apiUserAppleLoginSave = async (params: TLoginAppleSave) => {
  const response = await api.post('/fo/user/ssobottle/apple-save', params, {
    headers: { 'x-ssp-gateway-service-id': E_API_SERVICEID.USER },
  });
  const data = await response.data;

  return data;
};
